:root {
  --doc-height: 100vh;
 }

.str-chat {
  height: calc(100vh - 64px); /* fallback while JS loads */
  height: calc(var(--doc-height) - 64px);
}

.str-chat.messaging,
.str-chat__channel-list-messenger,
.str-chat__loading-channels {
  background-color: var(--grey-whisper);
}

.str-chat__custom-attachment-card--header {
  height: auto;
}

/* constrains undesired height attributes for our custom attachments */
.str-chat__message-attachment--pga_group_event,
.str-chat__message-attachment--pga_lesson_type,
.str-chat__message-attachment--pga_student_library_upload {
  height: unset !important;
}

.str-chat__channel-list-messenger__main {
  height: calc(100vh - 64px - 88px);
}

.str-chat-channel.messaging .str-chat__main-panel {
  padding: 0;
}
.str-chat-channel-list--open.messaging {
  width: 100%;
}

/* if device width is < 600px, do: */
@media screen and (max-width: 599px) {
  .str-chat-channel {
    max-height: 100%;
  }
  .str-chat-channel-list.messaging { /* channel list closed */
    left: -100vw;
    width: 100vw;
  }
  .str-chat-channel-list--open.messaging { /* channel list open */
    left: 0px;
    width: 100vw;
  }
}

@media screen and (min-width: 600px) and (max-width: 959px) {
  .str-chat-channel-list.messaging {
    left: -100vw;
  }

  .str-chat-channel-list--open.messaging {
    left: 57px;
    width: calc(100vw - 57px);
  }
}

.str-chat-channel-list.messaging {
  top: 60px;
  min-height: calc(100vh - 60px);
}

.str-chat-embed,
.str-chat-embed .str-chat {
  height: 100%;
}

.str-chat__send-button {
  display: block !important;
}

.messaging.str-chat .str-chat__thread {
  background-color: #D8D8D8;
  margin: 0 !important;
}
.str-chat__container {
  position: relative;
}
.str-chat__main-panel {
  display: none;
}
.str-chat__thread {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 100;
  max-height: 80vh;
  max-width: 40vw;
  width: 40vw;
  overflow: scroll;
  margin-top: 0px !important;
}

.str-chat__thread-header {
  background-color: rgba(0, 35, 75, 1) !important;
  color: white;
}

@media screen and (max-width: 960px) {
  .messaging.str-chat .str-chat__thread {
    max-width: 80vw;
    width: 80vw;
    max-height: 80vh;
    bottom: 0;
    top: unset;
  }
}